import { useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import { WorkflowType } from "../../../../../backend/common/workflow.types";

type DisabledProps =
  | {
      ifEmpty?: string[];
      ifValue?: {
        column: string[];
        values: (string | boolean)[];
      };
      ifWorkflowType?: WorkflowType;
    }
  | boolean
  | ((row: unknown) => boolean);

export interface MenuOption {
  label: string;
  onClick: any;
  color?: string;
  disabled?: DisabledProps;
}

export default function OptionsMenu({
  options,
  row,
}: {
  options: MenuOption[];
  row: any;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const setDisabled = (disabledOption: DisabledProps, row: any): boolean => {
    if (typeof disabledOption === "boolean") return disabledOption;
    if (typeof disabledOption === "function") {
      return disabledOption(row);
    }
    if (disabledOption.ifEmpty)
      return (
        disabledOption.ifEmpty.length !==
        disabledOption.ifEmpty
          .map((o: string) => {
            if (row[o]) return row[o];
          })
          .filter((m: any) => m).length
      );
    if (disabledOption.ifValue)
      return (
        disabledOption.ifValue &&
        disabledOption.ifValue.column.filter((c: any) =>
          disabledOption?.ifValue?.values.includes(row[c])
        ).length > 0
      );
    if (disabledOption.ifWorkflowType) {
      return row.workflowType === disabledOption.ifWorkflowType;
    }
    return false;
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Actions">
          <IconButton
            onClick={handleMenuClick}
            size="small"
            aria-controls={optionsOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={optionsOpen ? "true" : undefined}
          >
            <MoreHorizIcon fontSize="small" color={"secondary"} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={optionsOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {options.map((option: MenuOption, index: number) => {
          return (
            <MenuItem
              key={option.label + "-" + index}
              onClick={() => option.onClick(row)}
              disabled={
                option.disabled ? setDisabled(option.disabled, row) : false
              }
            >
              <Typography
                color={option.color ?? "text.primary"}
                fontSize={14}
                variant="caption"
              >
                {option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
